
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";
import SpaceCreateModal from "@/views/admin/master/SpaceCreateModal.vue";
import SpaceEditModal from "@/views/admin/master/SpaceEditModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AdminSpaces",
  components: { Pagination, SpaceCreateModal, SpaceEditModal },
  setup() {
    const store = useStore();

    let search = ref("");
    let currentPageURL = ref("");

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });
    let spaces = computed(() => {
      return store.getters[Modules.MASTER + "getSpaces"];
    });
    let spacesMeta = computed(() => {
      return store.getters[Modules.MASTER + "getMeta"];
    });
    let space = ref({});

    // let filteredEventEnvironments = computed(() =>
    //   Object.prototype.hasOwnProperty.call(eventEnvironments.value, "data")
    //     ? eventEnvironments.value.data.filter(
    //         (item) =>
    //           item.name.toUpperCase().includes(search.value.toUpperCase()) ||
    //           item.code.toUpperCase().includes(search.value.toUpperCase()) ||
    //           !search.value
    //       )
    //     : []
    // );

    onMounted(() => {
      setCurrentPageTitle("Spaces");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.MASTER + Actions.FETCH_SPACES, {
        search: search.value,
        url: currentPageURL.value,
      });
    }

    function deleteRecord(id) {
      if (confirm("Are you sure do you want to delete it?")) {
        store
          .dispatch(Modules.MASTER + Actions.DELETE_SPACE, id)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              fetchRecords();
            });
          })
          .catch(() => {
            Swal.fire({
              text: "Failed to delete the space. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    }

    return {
      formatDateTime,
      search,
      isLoading,
      spaces,
      spacesMeta,
      space,
      // filteredEventEnvironments,
      refresh,
      searchNow,
      gotoPage,
      deleteRecord,
    };
  },
});
